export const Titles = [
  {
    title: "ID",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Time",
    dbName: "created_at",
  },

  {
    title: "Slug",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Slug",
    dbName: "slug",
  },
  {
    title: "Name",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Name",
    dbName: "name",
  },
  {
    title: "Name in English",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Name in English",
    dbName: "name_en",
  },
  {
    title: "Projects",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "projects_count",
    dbName: "projects_count",
  },

  {
    title: "Actions",
    orderFunctionality: false,
  },
];
