import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdUpdate } from "react-icons/md";
import { ConfirmDeleteItem } from "../../../../components/_index";

const Row: React.FC<any> = ({
  values: [
    ShowUpdateSection,
    details,
    index,
    checkboxArr,
    setCheckboxArr,
    setFetchData,
  ],
}: any) => {
  const checkbox = (e: any) => {
    if (e.target.checked) {
      setCheckboxArr((prev: any) => [...prev, id]);
    } else {
      setCheckboxArr((prev: any) => prev.filter((elem: any) => elem !== id));
    }
  };
  const { id, name, slug, projects_count,name_en } = details;

  return (
    <tr
      className={`${index % 2 > 0 && "cd"} 
        ${checkboxArr.includes(id) && "checked"}
        `}
    >
      <td>
        <input
          className={"checkbox"}
          type="checkbox"
          onChange={checkbox}
          checked={checkboxArr.includes(id) ? true : false}
        />
      </td>
      {/* <td>{index + 1}</td> */}
      <td>{id}</td>
      <td>{slug}</td>
      <td>{name}</td>
      <td>{name_en}</td>
      <td>
        <div className={"bur"}>{projects_count}</div>
      </td>

      <td className={"rowActions"}>
        <div className={"update"}>
          <MdUpdate
            className={"update_in"}
            onClick={() => {
              ShowUpdateSection(id);
            }}
          />
        </div>

        <div
          className={`delete ${checkboxArr.length > 0 && "delete_in_hide"} `}
        >
          <AiOutlineDelete
            className={"delete_in"}
            onClick={() =>
              ConfirmDeleteItem(id, setFetchData, setCheckboxArr, "tag")
            }
          />
        </div>
      </td>
    </tr>
  );
};

export default Row;
