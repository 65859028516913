import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EmployeeService } from "../../../../../services/_index";
import {
  ImageUploader,
  TextInput,
  DescriptionInput,
  OrderInput,
  RadioInput,
  WebsiteUrlInput,
} from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
import resetFormData from "./resetFormData";

const UpdateForm: React.FC<any> = ({ hiddenUpdateSection, details }) => {
  const [resetImages, setResetImages] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL + "storage/";

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const resetMydata = () => {
    resetFormData(details, reset, setResetImages);
  };

  useEffect(() => {
    resetMydata();
  }, [details, reset, setValue]);

  const handleReset = () => {
    resetMydata();
  };

  useEffect(() => {
    if (resetImages) {
      setResetImages(false);
    }
  }, [resetImages]);

  const handleUpdateEmployee = async (id: any, data: any) => {
    try {
      await EmployeeService.updateEmployee(id, data, details);
      hiddenUpdateSection(true);
    } catch (error) {
      console.error(error);
    }
  };

  const bioInputs = [
    {
      name: "bio",
      label: "Employee Bio",
    },
    {
      name: "bio_en",
      label: "Employee Bio in English",
    },
  ];
  return (
    <form
      onSubmit={handleSubmit((data) => handleUpdateEmployee(details.id, data))}
    >
      <div className="inside_form">
        <ImageUploader
          name="image_path"
          register={register}
          setValue={setValue}
          errors={errors}
          myImg={baseUrl + details.image_path}
          reset={resetImages}
          isRequired={false}
        />

        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}

        <WebsiteUrlInput
          register={register}
          errors={errors}
          name="linkedin_url"
          label="LinkedIn URL"
        />

        {bioInputs.map((field) => (
          <DescriptionInput
            key={field.name}
            register={register}
            errors={errors}
            name={field.name}
            label={field.label}
          />
        ))}

        <OrderInput register={register} errors={errors} />

        <RadioInput
          name="active"
          label="Active"
          options={[
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ]}
          register={register}
        />
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenUpdateSection}>
          Discard
        </div>
        <div className={"reset"} onClick={handleReset}>
          Reset
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default UpdateForm;
