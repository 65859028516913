export const formFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Employee Name",
  },
  {
    name: "name_en",
    label: "Name in English",
    type: "text",
    placeholder: "Employee Name in English",
  },
  {
    name: "position",
    label: "Position",
    type: "text",
    placeholder: "Employee Position",
  },
];
