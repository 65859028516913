// File: AddForm.tsx
import React from "react";
import { useForm } from "react-hook-form";
import { TestimonialService } from "../../../../../services/_index";
import {
  ImageUploader,
  TextInput,
  DescriptionInput,
  OrderInput,
  RadioInput,
} from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
const AddForm: React.FC<{ hiddenAddSection: any }> = ({ hiddenAddSection }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const handleAddTestimonial = async (data: any) => {
    try {
      await TestimonialService.createTestimonial(data);
      hiddenAddSection(true);
    } catch (error) {
      console.error(error);
    }
  };

  const contentInputs = [
    {
      name: "content",
      label: "Client Feedback",
    },
    {
      name: "content_en",
      label: "Client Feedback in English",
    },
  ];

  return (
    <form onSubmit={handleSubmit((data) => handleAddTestimonial(data))}>
      <div className="inside_form">
        <ImageUploader
          name="image_path"
          label="Image"
          register={register}
          setValue={setValue}
          errors={errors}
        />

        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}

        {contentInputs.map((field) => (
          <DescriptionInput
            key={field.name}
            register={register}
            errors={errors}
            name={field.name}
            label={field.label}
          />
        ))}
        <OrderInput
          register={register}
          errors={errors}
          label="Rating"
          name="rating"
        />
        <OrderInput register={register} errors={errors} />

        <RadioInput
          name="is_featured"
          label="Featured"
          options={[
            { value: "0", label: "No", isDefault: true },
            { value: "1", label: "Yes" },
          ]}
          register={register}
        />

        <RadioInput
          name="active"
          label="Active"
          options={[
            { value: "0", label: "No" },
            { value: "1", label: "Yes", isDefault: true },
          ]}
          register={register}
        />
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenAddSection}>
          Discard
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default AddForm;
