export const formFields = [
    {
      name: "slug",
      label: "Slug",
      type: "text",
      placeholder: "example-project",
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Tag Name",
    },   
    {
      name:"name_en",
      label : "Name in English",
      type : "text",
      placeholder : "Tag Name in English",
    }
  ];
  