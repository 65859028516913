import { toast } from "react-hot-toast";
import api from "./api";

interface ProjectData {
  [key: string]: any;
}

interface PaginationParams {
  page?: number;
  per_page?: number;
  order_by?: string;
  order_direction?: "asc" | "desc";
}

interface SearchParams extends PaginationParams {
  search?: string;
  active?: boolean;
  is_featured?: boolean;
}

interface FetchDataOptions extends SearchParams {
  withoutLoading?: boolean;
}

type SetStateFunction<T> = (value: T | ((prevState: T) => T)) => void;

class ProjectService {
  private static readonly FILE_FIELDS = [
    "card_image",
    "detail_image_1",
    "detail_image_2",
  ];

  private static readonly DATA_FIELDS = [
    "slug",
    "title",
    "title_en",
    "subtitle",
    "subtitle_en",
    "framework",
    "programming_language",
    "backend_system",
    "order",
    "description",
    "description_en",
    "website_url",
    "is_featured",
    "active",
    "key_features",
    "key_features_en",
  ];

  private static prepareFormData(
    data: ProjectData,
    isUpdate: boolean = false,
    originalData: ProjectData = {}
  ): FormData {
    const formData = new FormData();

    this.FILE_FIELDS.forEach((field) => {
      if (data[`${field}_path`]?.[0] instanceof File) {
        formData.append(field, data[`${field}_path`][0]);
      }
    });

    if (!isUpdate) {
      this.DATA_FIELDS.forEach((key) => {
        if (data[key] !== undefined) {
          formData.append(key, this.formatValue(key, data[key]));
        }
      });

      if (data.tags?.length > 0) {
        data.tags.forEach((tag: { value: string }) => {
          formData.append("tags[]", tag.value);
        });
      }

      const colorPalette = { ...data.colorPalette, ring: data.ring };
      const colorPaletteString = JSON.stringify(colorPalette);
      formData.append("color_palette", colorPaletteString);
    } else {
      this.DATA_FIELDS.forEach((key) => {
        let formValue = data[key];
        let backendValue = originalData[key];

        if (key === "active" || key === "is_featured") {
          formValue = formValue === "1" || formValue === 1 ? true : false;
          backendValue = backendValue === true ? true : false;
        }
        if (formValue !== backendValue) {
          formData.append(key, data[key]);
        }
      });

      const colorPalette = { ...data.colorPalette, ring: data.ring };
      const colorPaletteString = JSON.stringify(colorPalette);
      if (colorPaletteString !== originalData.color_palette) {
        formData.append("color_palette", colorPaletteString);
      }

      const backendTagIds = originalData.tags.map((tag: any) => tag.id);
      const frontendTagIds = data.tags.map((tag: { value: string }) =>
        parseInt(tag.value)
      );

      if (JSON.stringify(backendTagIds) !== JSON.stringify(frontendTagIds)) {
        data.tags.forEach((tag: { value: string }) => {
          formData.append("tags[]", tag.value);
        });
      }
    }

    return formData;
  }

  private static formatValue(key: string, value: any): any {
    if (key === "active" || key === "is_featured") {
      return value === "1" || value === 1 || value === true ? "1" : "0";
    }
    return value;
  }

  private static async handleRequest(
    url: string,
    method: "GET" | "POST" | "PATCH" | "DELETE",
    data?: FormData | null,
    params?: Record<string, any>,
    options: { withoutLoading?: boolean } = {}
  ): Promise<any> {
    const { withoutLoading = false } = options;
    let toastId: string | undefined;

    if (!withoutLoading) {
      toastId = toast.loading("Processing request...", {
        style: {
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        },
      });
    }

    try {
      const config: any = { method, url };
      if (data) config.data = data;
      if (params) config.params = params;

      const response = await api(config);

      if (!withoutLoading && toastId) {
        toast.success(response.data.message || "Operation successful", {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      return response.data;
    } catch (error: any) {
      console.error("Error:", error);
      const message =
        error.response?.data?.message || "An error occurred. Please try again.";

      if (!withoutLoading && toastId) {
        toast.error(message, {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      throw error;
    }
  }

  public static async fetchProjects(
    setItems: SetStateFunction<any>,
    setIsLoading: SetStateFunction<boolean>,
    options: FetchDataOptions = {}
  ): Promise<void> {
    const {
      withoutLoading = false,
      search,
      active,
      is_featured,
      order_by = "order",
      order_direction = "asc",
      per_page = 15,
      page = 1,
    } = options;

    if (!withoutLoading) {
      setIsLoading(true);
      toast.remove();
    }

    try {
      const params: Record<string, string> = {
        order_by,
        order_direction,
        per_page: per_page.toString(),
        page: page.toString(),
      };

      if (search) params.search = search;
      if (active !== undefined) params.active = active.toString();
      if (is_featured !== undefined)
        params.is_featured = is_featured.toString();

      const response = await this.handleRequest(
        "/api/projects",
        "GET",
        null,
        params,
        { withoutLoading }
      );

      if (response.status) {
        setItems(response.items);
      }
    } finally {
      setIsLoading(false);
    }
  }

  public static async getProject(slug: string): Promise<any> {
    return this.handleRequest(`/api/projects/${slug}`, "GET");
  }

  public static async createProject(data: ProjectData): Promise<any> {
    const formData = this.prepareFormData(data);
    return this.handleRequest("/api/projects", "POST", formData);
  }

  public static async updateProject(
    id: string,
    data: ProjectData,
    originalData: ProjectData
  ): Promise<any> {
    const formData = this.prepareFormData(data, true, originalData);
    if ([...formData.entries()].length === 0) {
      toast.error(
        "No changes detected. Please modify the form before submitting.",
        {
          duration: 5000,
        }
      );
      return;
    }
    return this.handleRequest(
      `/api/projects/${id}?_method=PATCH`,
      "POST",
      formData
    );
  }

  public static async deleteProjects(ids: string | string[]): Promise<any> {
    const formData = new FormData();
    if (Array.isArray(ids)) {
      ids.forEach((id, index) => {
        formData.append(`ids[${index}]`, id);
      });
    } else {
      formData.append("ids[]", ids);
    }

    return this.handleRequest("/api/projects?_method=DELETE", "POST", formData);
  }

  public static async toggleProjectActive(id: string): Promise<any> {
    return this.handleRequest(`/api/projects/${id}/toggle-active`, "PATCH");
  }

  public static async reorderProjects(projectIds: string[]): Promise<any> {
    const formData = new FormData();
    projectIds.forEach((id, index) => {
      formData.append(`projects[${index}]`, id);
    });
    return this.handleRequest("/api/projects/reorder", "PATCH", formData);
  }

  public static async getProjectAuditLogs(id: string): Promise<any> {
    return this.handleRequest(`/api/projects/${id}/audit-logs`, "GET");
  }
}

export default ProjectService;
