export const formFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Client Name",
  },
  {
    name: "name_en",
    label: "Name in English",
    type: "text",
    placeholder: "Client Name in English",
  },
  {
    name: "company",
    label: "Company",
    type: "text",
    placeholder: "Client Company",
  },
  {
    name: "company_en",
    label: "Company in English",
    type: "text",
    placeholder: "Client Company in English",
  },
];
